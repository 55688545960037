import CenteredCard from '../../../../../components/centered-card'

const Receipt = () => {
  return (
    <>
      <CenteredCard />
    </>
  )
}

export default Receipt
