import React from 'react'

function TransCountIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect width="34" height="34" fill="#FFEEDB" rx="5"></rect>
      <path
        fill="#F1A85A"
        d="M21.99 10.05a.838.838 0 01-.838.837h-5.41a.838.838 0 010-1.675h5.41a.838.838 0 01.837.838zm-9.182-.838h-.862a.837.837 0 100 1.675h.862a.837.837 0 100-1.675zm8.344 3.35h-5.41a.837.837 0 100 1.675h5.41a.838.838 0 000-1.675zm-8.344 0h-.862a.837.837 0 100 1.675h.862a.837.837 0 100-1.675zm8.344 3.35h-5.41a.837.837 0 100 1.674h5.41a.837.837 0 000-1.675zm-8.344 0h-.862a.837.837 0 100 1.674h.862a.837.837 0 100-1.675zm14.79 8.305a4.785 4.785 0 11-9.57-.004 4.785 4.785 0 019.57.004zm-1.674 0a3.11 3.11 0 10-6.221 0 3.11 3.11 0 006.22 0zm-10.165-2.62H9.512a.838.838 0 01-.837-.837V7.512a.837.837 0 01.837-.837h14.102a.837.837 0 01.837.837v9.865a.837.837 0 101.675 0V7.512A2.515 2.515 0 0023.614 5H9.512A2.516 2.516 0 007 7.512V20.76a2.516 2.516 0 002.512 2.512h6.247a.837.837 0 000-1.675zm7.056 0a.837.837 0 00-.837.838v.944h-.444a.837.837 0 000 1.675h1.281a.838.838 0 00.837-.837v-1.782a.838.838 0 00-.837-.838z"
      ></path>
    </svg>
  )
}

export default TransCountIcon
