function DefectiveTerminalsIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="5" fill="#FFEEDB" />
      <path
        d="M17 6C14.8244 6 12.6977 6.64514 10.8887 7.85383C9.07979 9.06253 7.66989 10.7805 6.83733 12.7905C6.00477 14.8005 5.78693 17.0122 6.21137 19.146C6.6358 21.2798 7.68345 23.2398 9.22183 24.7782C10.7602 26.3165 12.7202 27.3642 14.854 27.7886C16.9878 28.2131 19.1995 27.9952 21.2095 27.1627C23.2195 26.3301 24.9375 24.9202 26.1462 23.1113C27.3549 21.3023 28 19.1756 28 17C28 15.5555 27.7155 14.1251 27.1627 12.7905C26.6099 11.4559 25.7996 10.2433 24.7782 9.22182C23.7567 8.20038 22.5441 7.39013 21.2095 6.83732C19.8749 6.28452 18.4445 6 17 6ZM17 25.8C15.2595 25.8 13.5581 25.2839 12.111 24.3169C10.6638 23.35 9.53592 21.9756 8.86986 20.3676C8.20381 18.7596 8.02954 16.9902 8.36909 15.2832C8.70864 13.5762 9.54676 12.0082 10.7775 10.7775C12.0082 9.54676 13.5762 8.70864 15.2832 8.36909C16.9902 8.02954 18.7596 8.20381 20.3676 8.86986C21.9756 9.53591 23.35 10.6638 24.3169 12.111C25.2839 13.5581 25.8 15.2595 25.8 17C25.8 19.3339 24.8729 21.5722 23.2225 23.2225C21.5722 24.8729 19.3339 25.8 17 25.8Z"
        fill="#F1A85A"
      />
      <path
        d="M17.0007 10.4004C16.7089 10.4004 16.4291 10.5163 16.2228 10.7226C16.0165 10.9289 15.9007 11.2087 15.9007 11.5004V16.5494L12.9197 19.5194C12.8045 19.618 12.711 19.7393 12.645 19.8758C12.5789 20.0123 12.5418 20.1609 12.536 20.3124C12.5301 20.4639 12.5557 20.615 12.611 20.7561C12.6663 20.8973 12.7501 21.0255 12.8573 21.1327C12.9645 21.2399 13.0927 21.3238 13.2339 21.3791C13.3751 21.4344 13.5261 21.4599 13.6776 21.4541C13.8291 21.4482 13.9777 21.4111 14.1142 21.3451C14.2507 21.2791 14.372 21.1855 14.4707 21.0704L17.7707 17.7704C17.9779 17.5673 18.0965 17.2905 18.1007 17.0004V11.5004C18.1007 11.2087 17.9848 10.9289 17.7785 10.7226C17.5722 10.5163 17.2924 10.4004 17.0007 10.4004Z"
        fill="#F1A85A"
      />
    </svg>
  )
}

export default DefectiveTerminalsIcon
