import React from 'react'

function ActiveUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#07F"
        stroke="#07F"
        strokeLinejoin="round"
        d="M8.975 9.954a3.976 3.976 0 003.975-3.977 3.976 3.976 0 10-3.975 3.977z"
      ></path>
      <path
        fill="#07F"
        fillRule="evenodd"
        stroke="#07F"
        strokeLinejoin="round"
        d="M3 15.485c-.001-.28.059-.556.174-.807.363-.762 1.385-1.166 2.233-1.349a12.69 12.69 0 011.856-.274 18.88 18.88 0 013.473 0c.624.046 1.245.137 1.857.274.848.183 1.87.549 2.233 1.349.232.513.232 1.109 0 1.622-.363.8-1.385 1.166-2.233 1.341-.611.143-1.232.237-1.857.282-.94.084-1.885.1-2.827.046-.218 0-.428 0-.646-.046a11.675 11.675 0 01-1.849-.282c-.855-.175-1.87-.54-2.24-1.34A1.978 1.978 0 013 15.484z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M14 16.131a.89.89 0 100-1.778.89.89 0 000 1.778z"
      ></path>
      <path
        fill="#005FCB"
        d="M14.225 12a.637.637 0 01.46.18c.124.117.19.278.19.448 0 .348.287.629.645.629.11 0 .22-.032.319-.086a.647.647 0 01.861.234l.215.37a.622.622 0 01-.237.856.63.63 0 00-.236.23.619.619 0 00.236.843.625.625 0 01.237.852l-.215.357a.64.64 0 01-.874.23.643.643 0 00-.966.546.635.635 0 01-.645.625h-.432a.636.636 0 01-.448-.186.62.62 0 01-.183-.442.633.633 0 00-.641-.628.625.625 0 00-.329.092.679.679 0 01-.49.063.66.66 0 01-.39-.294l-.202-.357a.615.615 0 01-.08-.492.639.639 0 01.313-.395.613.613 0 00.237-.23.625.625 0 00-.237-.843.615.615 0 01-.233-.843l.201-.354a.652.652 0 01.887-.234.687.687 0 00.323.082c.17 0 .334-.066.454-.183a.619.619 0 00.187-.442c0-.347.287-.628.643-.628h.43zm.126 2.362a.9.9 0 00-.97.186.851.851 0 00-.192.944.882.882 0 00.818.537h.003a.868.868 0 00.625-.253.85.85 0 00.262-.612.86.86 0 00-.546-.802z"
      ></path>
    </svg>
  )
}

export default ActiveUserIcon
