import React from 'react'

function CalenderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#879AA5"
        d="M22 10H2v9a3 3 0 003 3h14a3 3 0 003-3v-9zM7 8a1 1 0 01-1-1V3a1 1 0 012 0v4a1 1 0 01-1 1zm10 0a1 1 0 01-1-1V3a1 1 0 112 0v4a1 1 0 01-1 1z"
      ></path>
      <path
        fill="#001928"
        d="M19 4h-1v3a1 1 0 11-2 0V4H8v3a1 1 0 01-2 0V4H5a3 3 0 00-3 3v3h20V7a3 3 0 00-3-3z"
      ></path>
    </svg>
  )
}

export default CalenderIcon
