import React from 'react'

function ErrorBoundaryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="350"
      height="309"
      fill="none"
      viewBox="0 0 465 309"
    >
      <path
        fill="#C7DFFC"
        d="M241.155 224.5c-13.957 0-27.511-2.743-40.275-8.141a103.262 103.262 0 01-32.878-22.173 103.255 103.255 0 01-22.173-32.879c-5.398-12.763-8.141-26.318-8.141-40.274 0-13.957 2.743-27.511 8.141-40.275a103.263 103.263 0 0122.173-32.879c9.498-9.498 20.562-16.953 32.878-22.172 12.764-5.398 26.318-8.142 40.275-8.142 13.957 0 27.511 2.744 40.275 8.142 12.316 5.218 23.38 12.674 32.879 22.172 9.498 9.499 16.953 20.563 22.172 32.88 5.398 12.763 8.142 26.317 8.142 40.274 0 13.956-2.744 27.511-8.142 40.274a103.269 103.269 0 01-22.172 32.879c-9.499 9.499-20.563 16.954-32.879 22.173-12.764 5.413-26.318 8.141-40.275 8.141z"
      ></path>
      <path
        fill="#C7DFFC"
        d="M241.156 39.947c-44.718 0-81.101 36.382-81.101 81.101 0 44.718 36.383 81.101 81.101 81.101 44.718 0 81.101-36.383 81.101-81.101 0-44.719-36.383-81.102-81.101-81.102z"
      ></path>
      <path
        fill="#0A1324"
        d="M441.454 265.416s-10.035 11.929 0 21.547c0 0-14.941 2.132-14.941-12.153s14.941-9.394 14.941-9.394zm-177.799-88.825c.835-6.426-12.377-12.495-12.377-12.495-11.332-5.95-23.574-2.61-23.574-2.61-18.475 3.788-16.566 12.779-16.566 12.779l52.517 2.326z"
      ></path>
      <path
        fill="#6796C1"
        d="M417.672 267.996l-37.039-2.699-51.055-19.951c-16.909-10.706-36.1-53.68-36.1-53.68l-7.127 1.745-1.566 34.161 6.516 7.455s5.234 10.498 20.22 22.621l67.74 29.255 39.321.06"
      ></path>
      <path
        fill="#0A1324"
        d="M349.901 253.293l-20.338-7.947c-1.939-1.238-3.922-2.893-5.89-4.861-2.64 15-16.462 13.494-16.462 13.494a115.666 115.666 0 004.309 3.668l32.253 13.927 6.128-18.281z"
      ></path>
      <path
        fill="#87B4DE"
        d="M290.244 257.349l3.251-65.683s-4.101-9.767-29.837-15.075c-56.603-11.675-87.573 8.902-87.573 8.902l-1.565 53.963s.074 21.621.178 39.112c.105 17.49 30.926 14.747 30.926 14.747l69.679 1.073 14.926-1.073v-35.966h.015z"
      ></path>
      <path
        fill="#71A9DD"
        d="M211.57 185.627s45.3-10.557 74.779 7.799l-1.566 34.161-69.917-1.7-3.296-40.26z"
      ></path>
      <path
        fill="#87B4DE"
        d="M192.904 69.992l-10.11 45.658a11.832 11.832 0 001.089 8.096l15.463 29.36a11.881 11.881 0 009.185 6.263l87.364 9.692c5.576.611 10.825-2.759 12.57-8.082l16.297-49.639c1.342-4.1.358-8.603-2.579-11.764l-26.691-28.704a11.842 11.842 0 00-7.769-3.743L205.4 60.747c-5.89-.462-11.214 3.49-12.496 9.245z"
      ></path>
      <path
        fill="#6796C1"
        d="M324.763 111.311l-16.298 49.654a11.846 11.846 0 01-12.57 8.082l-87.364-9.693a11.822 11.822 0 01-4.726-1.58l13.599-56.737c1.431-5.979 7.187-9.856 13.255-8.961l94.581 13.971c.239 1.76.089 3.549-.477 5.264z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M216.494 84.5L198.75 64.118"
      ></path>
      <path
        fill="#fff"
        d="M232.493 131.724l23.888 4.19s-5.055 11.347-15.687 9.081c-10.631-2.267-8.201-13.271-8.201-13.271zm42.914 6.784l24.201 3.892s-2.61 9.737-15.866 8.171c-13.241-1.565-8.335-12.063-8.335-12.063z"
      ></path>
      <path
        fill="#6796C1"
        d="M330.01 297.207l-39.828-3.877s-8.752-4.28-8.752-17.759c0-13.48 8.752-18.207 8.752-18.207l36.19.134"
      ></path>
      <path
        fill="#3279AF"
        d="M360.771 217.06c-17.505-3.862-35.861 23.306-35.861 56.378 0 33.073 16.507 35.041 38.426 35.041"
      ></path>
      <path
        fill="#0A1324"
        d="M360.77 217.06c19.936 1.759 31.164 87.453 2.58 91.419-28.585 3.967-26.363-93.522-2.58-91.419z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M294.671 257.394s-8.976 20.726 5.487 37.009m7.56-36.92s-4.145 24.962 11.333 38.918"
      ></path>
      <path
        fill="#6796C1"
        d="M71.93 260.018s34.981 3.52 57.765-10.646c23.783-14.792 46.388-63.879 46.388-63.879s8.649-1.536 13.838 11.661c5.189 13.181-1.745 23.171-1.745 23.171s-25.393 40.424-50.012 54.59c-24.618 14.165-64.952 7.023-64.952 7.023"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M169.925 197.69s7.337 3.445 9.558 35.295m-21.322-15.299s8.573 9.558 5.532 34.996m-22.248-13.226s6.904 16.969-.775 33.937m-21.382-18.966s1.55 19.608-11.393 28.838m-15.194-23.291s-.298 16.312-9.26 22.813"
      ></path>
      <path
        fill="#0A1324"
        d="M451.671 286.903l13.331-1.491v8.067l-13.584 6.158m0-46.97l13.584 5.01v7.605l-13.331.015"
      ></path>
      <path
        fill="#3279AF"
        d="M428.884 276.137c.164 13.629 22.799 10.766 22.799 10.766l-.254 12.734c-17.088-.567-35.056-4.697-35.324-22.367-.268-17.669 15.09-25.199 35.354-24.603l.283 12.63c-.015 0-23.022-2.788-22.858 10.84z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M302.811 210.275s2.983 23.947-4.756 34.326m19.145-11.616s5.368 15.373 0 24.499m89.153 9.692s-3.235 13.45 6.039 19.787m-24.185-21.114s-3.206 14.27 5.651 21.054"
      ></path>
      <path
        fill="#3279AF"
        d="M244.316 77.299s1.119-19.042 24.335-16.596c23.232 2.46 21.949 21.979 21.949 21.979s-2.43 4.711-17.908 3.37c-15.478-1.343-28.376-8.753-28.376-8.753z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M263.283 67.04L244.316 9.9"
      ></path>
      <path
        fill="#3279AF"
        d="M244.316 17.804a8.902 8.902 0 100-17.805 8.902 8.902 0 000 17.805z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M274.512 69.306l20.473-28.942 28.524 26.676"
      ></path>
      <path
        fill="#3279AF"
        d="M325.285 78.193a8.902 8.902 0 100-17.804 8.902 8.902 0 000 17.804z"
      ></path>
      <path
        fill="#0A1324"
        d="M74.674 282.937c-4.712 14.583-33.788 15.91-33.788 15.91l-14.255-13.629 3.25-13.539-18.504 4.577L0 267.996l2.4-21.413s38.725-7.097 59.809 1.984c21.114 9.095 17.177 19.801 12.465 34.37z"
      ></path>
      <path
        fill="#3279AF"
        d="M49.174 272.827l-16.998-10.661s35.115-.15 22.873-7.65-40.617 2.237-40.617 2.237l-12.034-10.17s38.724-7.097 59.808 1.984c6.01 2.594 9.99 5.308 12.496 8.216 7.843 11.705-25.528 16.044-25.528 16.044z"
      ></path>
      <path
        fill="#87B4DE"
        d="M49.177 272.827l-8.29 26.02-14.24-13.629 5.532-23.052 16.998 10.661zM14.45 256.753l-3.072 19.503-11.362-8.26 2.4-21.413 12.034 10.17z"
      ></path>
      <path
        fill="#6796C1"
        d="M245.467 297.207l-39.828-3.877s-8.752-4.28-8.752-17.759c0-13.48 8.752-18.207 8.752-18.207l36.19.134"
      ></path>
      <path
        fill="#3279AF"
        d="M276.228 217.06c-17.505-3.862-35.861 23.306-35.861 56.378 0 33.073 16.507 35.041 38.426 35.041"
      ></path>
      <path
        fill="#0A1324"
        d="M276.227 217.06c19.936 1.759 31.164 87.453 2.58 91.419-28.585 3.967-26.363-93.522-2.58-91.419z"
      ></path>
      <path
        stroke="#0A1324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M210.124 257.394s-8.976 20.726 5.488 37.009m7.559-36.92s-4.145 24.962 11.333 38.918"
      ></path>
    </svg>
  )
}

export default ErrorBoundaryIcon
