import React from 'react'

function TimerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#374B58"
        d="M8.333 1.667H5c-.5 0-.833-.334-.833-.834C4.167.333 4.5 0 5 0h3.333c.5 0 .833.333.833.833 0 .5-.333.834-.833.834z"
      ></path>
      <path
        fill="#F9FAFC"
        d="M6.667 10c-.5 0-.834-.334-.834-.834v-2.5c0-.5.334-.833.834-.833.5 0 .833.333.833.833v2.5c0 .5-.333.834-.833.834z"
      ></path>
      <path
        fill="#C1CACF"
        d="M6.667 2.5C3 2.5 0 5.5 0 9.167c0 3.666 3 6.666 6.667 6.666 3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667zM7.5 9.167c0 .5-.333.833-.833.833-.5 0-.834-.333-.834-.833v-2.5c0-.5.334-.834.834-.834.5 0 .833.334.833.834v2.5z"
      ></path>
      <path
        fill="#374B58"
        d="M1.417 5.083c.333-.417.75-.833 1.166-1.167l-.75-.75a.806.806 0 00-1.166 0 .806.806 0 000 1.167l.75.75zm10.5 0l.75-.75a.806.806 0 000-1.167.806.806 0 00-1.167 0l-.75.75c.416.334.833.75 1.166 1.167zM6.667 10.416a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      ></path>
    </svg>
  )
}

export default TimerIcon
