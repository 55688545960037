import signoutlogout from '../assets/images/pngs/profile.svg'
export const noteDAta = [
  {
    imageUrl: signoutlogout,
    name: 'Francis Odega',
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum tempus egestas sed sed risus pretium.',
    date: '02/08/2022, 03:33 am',
  },
  {
    imageUrl: signoutlogout,
    name: 'Francis Odega',
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum tempus egestas sed sed risus pretium.',
    date: '02/08/2022, 03:33 am',
  },
  {
    imageUrl: signoutlogout,
    name: 'Francis Odega',
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum tempus egestas sed sed risus pretium.',
    date: '02/08/2022, 03:33 am',
  },
  {
    imageUrl: signoutlogout,
    name: 'Francis Odega',
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum tempus egestas sed sed risus pretium.',
    date: '02/08/2022, 03:33 am',
  },
  {
    imageUrl: signoutlogout,
    name: 'Francis Odega',
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum tempus egestas sed sed risus pretium.',
    date: '02/08/2022, 03:33 am',
  },
]
