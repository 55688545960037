import { IconProps } from '../types'

const BulkUpload = ({ width, height, color, fill, className }: IconProps) => {
  return (
    <>
      <svg
        className={className}
        width={width ? width : '75'}
        height={height ? height : '75'}
        viewBox="0 0 75 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="37.7858"
          cy="37.2135"
          r="36.1412"
          stroke={color ? color : '#A5B0B7'}
          strokeDasharray="6 6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5 6.25C20.2531 6.25 6.25 20.2531 6.25 37.5C6.25 54.7469 20.2531 68.75 37.5 68.75C54.7469 68.75 68.75 54.7469 68.75 37.5C68.75 20.2531 54.7469 6.25 37.5 6.25ZM37.5 12.5C51.2969 12.5 62.5 23.7031 62.5 37.5C62.5 51.2969 51.2969 62.5 37.5 62.5C23.7031 62.5 12.5 51.2969 12.5 37.5C12.5 23.7031 23.7031 12.5 37.5 12.5ZM34.375 29.4188V53.125C34.375 54.85 35.775 56.25 37.5 56.25C39.225 56.25 40.625 54.85 40.625 53.125V29.3188L44.6875 33.3062C45.9156 34.5125 47.8969 34.4937 49.1063 33.2625C50.3125 32.0344 50.2938 30.0531 49.0625 28.8438L39.6875 19.6438C38.4656 18.4438 36.5031 18.4531 35.2906 19.6656L25.9156 29.0406C24.6969 30.2594 24.6969 32.2406 25.9156 33.4594C27.1344 34.6781 29.1156 34.6781 30.3344 33.4594L34.375 29.4188Z"
          fill={fill ? fill : '#007BFF'}
        />
      </svg>
    </>
  )
}
export default BulkUpload
