const SuccessIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.8661 0C35.9924 0 27.318 2.6392 19.9398 7.58387C12.5616 12.5285 6.81105 19.5566 3.41525 27.7792C0.0194398 36.0019 -0.869058 45.0499 0.862109 53.779C2.59328 62.5082 6.86636 70.5264 13.141 76.8198C19.4156 83.1131 27.41 87.399 36.1132 89.1353C44.8163 90.8716 53.8374 89.9805 62.0356 86.5746C70.2338 83.1686 77.2409 77.4008 82.1709 70.0006C87.1008 62.6004 89.7322 53.9001 89.7322 45C89.7186 33.0694 84.9872 21.6314 76.5762 13.1953C68.1651 4.75909 56.7611 0.013649 44.8661 0V0ZM68.2279 34.2598L40.467 62.1035C40.0505 62.5214 39.556 62.853 39.0117 63.0792C38.4674 63.3054 37.884 63.4219 37.2948 63.4219C36.7056 63.4219 36.1222 63.3054 35.578 63.0792C35.0337 62.853 34.5391 62.5214 34.1226 62.1035L21.5043 49.4472C20.6717 48.6016 20.2065 47.46 20.2102 46.2716C20.2139 45.0831 20.6863 43.9444 21.5241 43.1041C22.3619 42.2637 23.4972 41.7899 24.6821 41.7862C25.867 41.7824 27.0053 42.2489 27.8484 43.084L37.2948 52.5586L61.8838 27.8965C62.7269 27.0615 63.8652 26.5949 65.0501 26.5987C66.235 26.6024 67.3703 27.0762 68.2081 27.9166C69.0459 28.7569 69.5182 29.8956 69.522 31.0841C69.5257 32.2725 69.0604 33.4141 68.2279 34.2598Z"
        fill="#34A853"
      />
    </svg>
  )
}

export default SuccessIcon
