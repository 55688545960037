import React from 'react'

function Terminal2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect width="34" height="34" fill="#FFEEDB" rx="5"></rect>
      <path
        fill="#F1A85A"
        d="M24.347 6.086a.522.522 0 00-.495-.031l-1.605.775-1.606-.775a.473.473 0 00-.442 0l-1.596.775-1.595-.775a.473.473 0 00-.443 0l-1.606.775-1.595-.775a.473.473 0 00-.442 0l-1.596.775-1.595-.775a.493.493 0 00-.494.031.52.52 0 00-.237.44v20.948a.531.531 0 00.237.44.494.494 0 00.494.031l1.595-.775 1.596.775a.553.553 0 00.226.052.508.508 0 00.216-.052l1.595-.775 1.606.775a.474.474 0 00.443 0l1.595-.775 1.596.775a.473.473 0 00.442 0l1.606-.775 1.605.775a.493.493 0 00.495-.032.52.52 0 00.236-.44V6.527a.531.531 0 00-.236-.44zM13.982 15h4.384l-1.348-1.372a.508.508 0 01-.114-.566.508.508 0 01.114-.167.516.516 0 01.366-.155.507.507 0 01.365.155l2.223 2.263a.527.527 0 01-.37.89h-5.62a.51.51 0 01-.364-.154.528.528 0 010-.74.51.51 0 01.364-.154zM19.602 19h-4.385l1.348 1.372a.506.506 0 01.114.567.507.507 0 01-.114.167.51.51 0 01-.36.157.515.515 0 01-.37-.157l-2.224-2.263a.527.527 0 01.37-.89h5.62a.51.51 0 01.364.154.528.528 0 010 .74.51.51 0 01-.363.153z"
      ></path>
    </svg>
  )
}

export default Terminal2Icon
