import React from 'react'

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
    >
      <g
        stroke="#374B58"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="0.7"
      >
        <path
          d="M25.782 13.81l-.525-.91a1.613 1.613 0 00-2.2-.595 1.606 1.606 0 01-2.2-.57 1.544 1.544 0 01-.216-.773 1.613 1.613 0 00-1.613-1.66H17.97a1.606 1.606 0 00-1.606 1.614 1.613 1.613 0 01-1.613 1.59 1.543 1.543 0 01-.772-.216 1.613 1.613 0 00-2.2.594l-.563.927a1.613 1.613 0 00.586 2.2 1.613 1.613 0 010 2.794 1.606 1.606 0 00-.586 2.192l.532.919a1.613 1.613 0 002.2.625 1.598 1.598 0 012.192.587c.14.233.214.5.217.772 0 .89.722 1.613 1.613 1.613h1.058c.888 0 1.609-.718 1.613-1.606a1.605 1.605 0 011.613-1.613c.271.007.537.082.772.216.77.444 1.753.181 2.2-.587l.556-.926a1.606 1.606 0 00-.587-2.2 1.605 1.605 0 01-.587-2.2c.14-.244.343-.446.587-.586a1.613 1.613 0 00.587-2.193v-.007z"
          clipRule="evenodd"
        ></path>
        <path d="M18.5 19.631a2.223 2.223 0 100-4.446 2.223 2.223 0 000 4.446z"></path>
      </g>
    </svg>
  )
}

export default SettingsIcon
