import React from 'react'

function Users2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect width="34" height="34" fill="#DFF9E6" rx="5"></rect>
      <path
        fill="#34A853"
        d="M22.838 7.572A4.875 4.875 0 0022.1 5h1.823a2.522 2.522 0 011.788.757 2.59 2.59 0 01.739 1.815v4.676c0 .372-.08.74-.236 1.077a2.546 2.546 0 01-.664.875 1.184 1.184 0 00-.094 1.7c.145.155.33.266.532.322a5.34 5.34 0 012.978 2.321 5.46 5.46 0 01.726 3.734h-3.588a7.896 7.896 0 00-.805-4.402 7.766 7.766 0 00-3.06-3.231c.969-1.438.53-5.412.6-7.072zm-8.077 7.82c-.044.208-.144.4-.289.554a1.152 1.152 0 01-.53.323 5.339 5.339 0 00-2.981 2.294 5.459 5.459 0 00-.757 3.714h13.592a5.46 5.46 0 00-.752-3.711 5.34 5.34 0 00-2.975-2.297 1.153 1.153 0 01-.532-.322 1.175 1.175 0 01-.256-1.182 1.17 1.17 0 01.35-.519c.281-.239.508-.537.663-.874.156-.338.237-.705.237-1.078V7.618a2.59 2.59 0 00-.74-1.814 2.522 2.522 0 00-1.787-.757h-2.008a2.534 2.534 0 00-1.792.756 2.6 2.6 0 00-.746 1.815v4.676c.002.372.084.74.24 1.076.154.337.38.636.66.877a1.17 1.17 0 01.392 1.145h.011zm-3-.736c-.98-1.462-.496-5.413-.6-7.084.002-.91.258-1.802.739-2.572h-1.823a2.522 2.522 0 00-1.788.757 2.589 2.589 0 00-.739 1.815v4.676c0 .372.08.74.236 1.077.156.337.382.636.664.875a1.17 1.17 0 01.384 1.145c-.045.209-.145.4-.29.555a1.15 1.15 0 01-.532.322 5.364 5.364 0 00-2.971 2.325 5.483 5.483 0 00-.733 3.73h3.577a7.885 7.885 0 01.806-4.397 7.753 7.753 0 013.059-3.224h.011zm19.085 10.006H3.154c-.306 0-.6.123-.816.342a1.177 1.177 0 000 1.654c.216.219.51.342.816.342h27.692c.306 0 .6-.123.816-.342a1.177 1.177 0 000-1.654 1.146 1.146 0 00-.816-.342z"
      ></path>
    </svg>
  )
}

export default Users2Icon
