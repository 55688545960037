import { IconProps } from '../types'

export function DangerWarning({
  width,
  height,
  color,
  fill,
  className,
}: IconProps) {
  return (
    <svg
      width={width || '99'}
      height={height || '99'}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.5 0C22.1952 0 0 22.1952 0 49.5C0 76.8048 22.1952 99 49.5 99C76.8048 99 99 76.8048 99 49.5C99 22.1952 76.8048 0 49.5 0ZM44.7097 73.4516V69.9706C44.7097 67.3198 46.8493 65.1802 49.5 65.1802C52.1507 65.1802 54.2903 67.3198 54.2903 69.9706V73.4516C54.2903 76.1021 52.1507 78.2419 49.5 78.2419C46.8493 78.2419 44.7097 76.1021 44.7097 73.4516ZM54.2903 56.0148C54.2903 58.6335 52.1507 60.8051 49.5 60.8051C46.8493 60.8051 44.7097 58.6335 44.7097 56.0148V25.5484C44.7097 22.8977 46.8493 20.7581 49.5 20.7581C52.1507 20.7581 54.2903 22.8977 54.2903 25.5484V56.0148Z"
        fill="#EA4336"
      />
    </svg>
  )
}

export default DangerWarning
