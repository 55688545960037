export enum Action {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ERROR = 'ERROR',
  COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR',
  VERIFY_OTP = 'VERIFY_OTP',
  IS_FETCHING = 'IS_FETCHING',
  GET_FILTERS = 'GET_FILTERS',
  SAVETERMINALREQ = 'SAVETERMINALREQ',
}
