import React from 'react'

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 13 16"
    >
      <path
        fill="#001928"
        d="M10.833 7.222a.722.722 0 01-.722-.722V5.056c0-1.99-1.62-3.612-3.611-3.612a3.616 3.616 0 00-3.611 3.612V6.5a.722.722 0 11-1.445 0V5.056A5.061 5.061 0 016.5 0a5.061 5.061 0 015.056 5.056V6.5a.722.722 0 01-.723.722z"
      ></path>
      <path
        fill="#001928"
        d="M10.833 6.5V5.056a4.334 4.334 0 00-8.666 0V6.5h.722V5.056c0-1.99 1.62-3.612 3.611-3.612 1.99 0 3.611 1.621 3.611 3.612V6.5h.722z"
      ></path>
      <path
        fill="#A5B0B7"
        d="M11.556 5.778H1.444C.65 5.778 0 6.426 0 7.222v7.222c0 .796.649 1.445 1.444 1.445h10.112c.795 0 1.444-.649 1.444-1.444V7.221c0-.796-.649-1.444-1.444-1.444z"
      ></path>
      <path
        fill="#001928"
        d="M7.944 13H5.056l.46-1.843a1.43 1.43 0 01-.46-1.046c0-.796.648-1.444 1.444-1.444.796 0 1.444.648 1.444 1.444a1.43 1.43 0 01-.46 1.046L7.944 13z"
      ></path>
    </svg>
  )
}

export default LockIcon
