import { IconProps } from '../types'

export const Upload = ({
  width,
  height,
  color,
  fill,
  className,
}: IconProps) => {
  return (
    <>
      <svg
        className={className}
        width={width ? width : '50'}
        height={height ? height : '50'}
        viewBox="0 0 50 50 "
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0006 0.833984C11.6631 0.833984 0.833984 11.6631 0.833984 25.0006C0.833984 38.3382 11.6631 49.1673 25.0006 49.1673C38.3382 49.1673 49.1673 38.3382 49.1673 25.0006C49.1673 11.6631 38.3382 0.833984 25.0006 0.833984ZM25.0006 5.66732C35.6702 5.66732 44.334 14.3311 44.334 25.0006C44.334 35.6702 35.6702 44.334 25.0006 44.334C14.3311 44.334 5.66732 35.6702 5.66732 25.0006C5.66732 14.3311 14.3311 5.66732 25.0006 5.66732ZM22.584 18.7512V37.084C22.584 38.418 23.6666 39.5006 25.0006 39.5006C26.3346 39.5006 27.4173 38.418 27.4173 37.084V18.6738L30.559 21.7575C31.5087 22.6903 33.0409 22.6758 33.9762 21.7236C34.909 20.7739 34.8945 19.2417 33.9423 18.3065L26.6923 11.1918C25.7474 10.2638 24.2297 10.2711 23.2921 11.2087L16.0421 18.4587C15.0996 19.4012 15.0996 20.9334 16.0421 21.8759C16.9846 22.8184 18.5167 22.8184 19.4592 21.8759L22.584 18.7512Z"
          fill={fill ? fill : '#007BFF'}
        />
      </svg>
    </>
  )
}
