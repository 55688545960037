export const DiasbleTeminalIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15" fill="#EA4336" stroke="white" />
      <g clip-path="url(#clip0_12556_61949)">
        <path
          d="M15.4994 5.96094C10.2401 5.96094 5.96094 10.2401 5.96094 15.4994C5.96094 20.7587 10.2401 25.0379 15.4994 25.0379C20.7587 25.0379 25.0379 20.7587 25.0379 15.4994C25.0379 10.2401 20.7587 5.96094 15.4994 5.96094ZM8.34555 15.4994C8.34554 14.0149 8.81044 12.5677 9.67498 11.3609L19.6379 21.3238C18.4311 22.1884 16.9839 22.6533 15.4994 22.6532C11.5541 22.6532 8.34555 19.4447 8.34555 15.4994ZM21.3238 19.6379L11.3621 9.67498C12.5685 8.81068 14.0153 8.34579 15.4994 8.34555C19.4447 8.34555 22.6532 11.5541 22.6532 15.4994C22.6533 16.9839 22.1884 18.4311 21.3238 19.6379Z"
          fill="#F9FAFC"
        />
      </g>
      <defs>
        <clipPath id="clip0_12556_61949">
          <rect
            width="19.0769"
            height="19.0769"
            fill="white"
            transform="translate(5.96094 5.96094)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
