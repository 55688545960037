export enum Color {
  alerzoBlue = 'rgba(0, 108, 232, 1)',
  alerzoLightBlue = '#006ce891',
  alerzoWhite = '#ffffff',
  alerzoGray = '#E5E5E5',
  alerzoBgColor = '#F9FAFC',
  alerzoGray2 = '#A5B0B7',
  alerzoGray3 = '#F6F7F9',
  alerzoDarkGray = '#374B58',
  alerzoLightGray = '0px 6px 60px rgba(0, 0, 0, 0.08)',
  alerzoOrange = 'rgba(241, 168, 90, 1)',

  alerzoLight = '#fafafa99',
  alerzoDanger = '#EA4336',
  alerzoDanger100 = '#FDECEB',
  alerzoBlack = '#001928',
  alerzoBlueTint = '#0077FF',
  alerzoGreyTint = '#879AA5',
  alerzoGreyBg = '#F6F6F6',
  alerzoGreenBg = '#DDF3E3',
  alerzoGreen = '#34A853',
  alerzoMediumGray = 'rgba(121, 136, 146, 1)',
  alerzoGrayTwo = '#798892',
  alerzoGrayBorder = '#E8EBEE',
  alerzoErrorBg = 'rgba(243, 221, 221, 1)',
  alerzoWarning = '#F1A85A',
  alerzoWarningBg = 'rgba(241, 168, 90, 0.1)',
  alerzoStatusSuccess = 'rgba(52, 168, 83, 0.1)',
  alerzoStatusPending = 'rgba(241, 168, 90, 0.1)',
  alerzoStatusFailed = 'rgba(234, 67, 54, 0.1)',
  alerzoBlue2 = '#F3F9FF',
  alerzoBlue3 = '#CBE5FF',
  alerzoBlue4 = 'rgba(209, 231, 255, 0.5)',
  alerzoGray4 = '#C1CACF',
  alerzoBlue5 = '#E7F2FF',
  alerzoBlueBorder = '#C8DFFB',
  alerzoBlue6 = '#7890B5',
  alerzoBlue7 = '#F2FAFF',
  alerzoBlue8 = '#007BFF',
  alerzoBlue9 = '#EFF7FF',
  alerzoDeepBlue = '#7890B5',
  alerzoLightBlue2 = 'rgba(232, 243, 255, 0.4)',
  alerzoLightGray4 = '#e8e8e8',
  alerzoLightGray5 = '#9CA8AF',
  alerzoPureBlack = '#000000',
  alerzoBlackishBlue = '#D7E2E9',
  alerzoGrayishBlue = '#5976A1',
  alerzoGrayishBlue2 = '#475E80',
  alerzoDisabledBg = '#F0F5F8',
  alerzoDisabled = '#A1A8B7',
}
