export const TABS = [
  {
    label: 'Employees',
    value: 'employees',
    title: '',
  },
  {
    label: 'Roles and Permissions',
    value: 'roles-permissions',
    title: '',
  },
  {
    label: 'Pending Invites',
    value: 'pending-invites',
    title: '',
  },
]
