import React from 'react'

function BriefcaseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect width="34" height="34" fill="#E1EFFF" rx="5"></rect>
      <path
        fill="#07F"
        d="M13.804 9.5h7.044V7.75h-7.044V9.5zm15.848 8.75v6.563a2.1 2.1 0 01-.646 1.544c-.431.429-.95.643-1.555.643H7.201a2.126 2.126 0 01-1.554-.643A2.1 2.1 0 015 24.813V18.25h9.245v2.188c0 .236.087.442.26.615.175.173.381.26.62.26h4.402a.849.849 0 00.62-.26.838.838 0 00.26-.616V18.25h9.245zm-10.565 0V20h-3.522v-1.75h3.522zm10.565-6.563v5.25H5v-5.25a2.1 2.1 0 01.647-1.544c.43-.429.949-.643 1.554-.643h4.843V7.312c0-.364.128-.674.385-.93.256-.254.568-.382.935-.382h7.924c.367 0 .679.128.936.383.256.255.385.565.385.93V9.5h4.842c.605 0 1.124.214 1.555.643a2.1 2.1 0 01.646 1.544z"
      ></path>
    </svg>
  )
}

export default BriefcaseIcon
