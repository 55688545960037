import React from 'react'
import { IconProps } from '../types'

function AddIcon({ onClick }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
      onClick={onClick}
    >
      <path
        fill="#0077FF"
        d="M15.0513 2.5C12.5706 2.5 10.1456 3.23311 8.08305 4.60663C6.02045 5.98015 4.41284 7.93238 3.46353 10.2165C2.51422 12.5005 2.26584 15.0139 2.74979 17.4386C3.23375 19.8634 4.4283 22.0907 6.1824 23.8388C7.9365 25.587 10.1714 26.7775 12.6044 27.2598C15.0374 27.7421 17.5592 27.4946 19.8511 26.5485C22.1429 25.6024 24.1018 24.0002 25.48 21.9446C26.8582 19.889 27.5938 17.4723 27.5938 15C27.5938 13.3585 27.2693 11.733 26.639 10.2165C26.0087 8.69989 25.0848 7.3219 23.9201 6.16117C22.7555 5.00043 21.3728 4.07969 19.8511 3.45151C18.3294 2.82332 16.6984 2.5 15.0513 2.5ZM15.0513 25C13.0667 25 11.1268 24.4135 9.47669 23.3147C7.82661 22.2159 6.54053 20.6541 5.78108 18.8268C5.02163 16.9996 4.82293 14.9889 5.21009 13.0491C5.59725 11.1093 6.5529 9.32746 7.95618 7.92893C9.35945 6.53041 11.1473 5.578 13.0937 5.19215C15.0401 4.8063 17.0576 5.00433 18.8911 5.7612C20.7246 6.51808 22.2917 7.79981 23.3942 9.4443C24.4968 11.0888 25.0853 13.0222 25.0853 15C25.0853 17.6522 24.0281 20.1957 22.1464 22.0711C20.2646 23.9464 17.7125 25 15.0513 25ZM20.0683 13.75H16.3055V10C16.3055 9.66848 16.1734 9.35054 15.9382 9.11612C15.7029 8.8817 15.3839 8.75 15.0513 8.75C14.7186 8.75 14.3996 8.8817 14.1644 9.11612C13.9292 9.35054 13.797 9.66848 13.797 10V13.75H10.0343C9.70164 13.75 9.38261 13.8817 9.1474 14.1161C8.91218 14.3505 8.78003 14.6685 8.78003 15C8.78003 15.3315 8.91218 15.6495 9.1474 15.8839C9.38261 16.1183 9.70164 16.25 10.0343 16.25H13.797V20C13.797 20.3315 13.9292 20.6495 14.1644 20.8839C14.3996 21.1183 14.7186 21.25 15.0513 21.25C15.3839 21.25 15.7029 21.1183 15.9382 20.8839C16.1734 20.6495 16.3055 20.3315 16.3055 20V16.25H20.0683C20.4009 16.25 20.7199 16.1183 20.9552 15.8839C21.1904 15.6495 21.3225 15.3315 21.3225 15C21.3225 14.6685 21.1904 14.3505 20.9552 14.1161C20.7199 13.8817 20.4009 13.75 20.0683 13.75Z"
      ></path>
    </svg>
  )
}

export default AddIcon
