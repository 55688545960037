import React from 'react'

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
    >
      <g fill="#72767C" fillRule="evenodd" clipRule="evenodd" opacity="0.7">
        <path d="M17.19 19.803a.833.833 0 01.865.802c.039 1.037.094 1.793.148 2.336.053.534.376.856.825.91.53.066 1.279.119 2.305.119 1.026 0 1.774-.053 2.305-.118.449-.055.772-.377.825-.911.1-1.007.203-2.747.203-5.638 0-2.89-.103-4.63-.203-5.638-.053-.534-.376-.856-.826-.91a19.221 19.221 0 00-2.304-.119 19.22 19.22 0 00-2.305.118c-.45.055-.772.377-.825.91-.054.543-.11 1.3-.148 2.337a.833.833 0 01-1.666-.063c.04-1.064.097-1.856.155-2.438.122-1.226.977-2.24 2.282-2.4.608-.075 1.424-.13 2.507-.13 1.083 0 1.899.055 2.507.13 1.305.16 2.16 1.175 2.281 2.4.108 1.079.212 2.877.212 5.803s-.104 4.724-.212 5.803c-.122 1.225-.976 2.24-2.281 2.4-.608.074-1.424.13-2.507.13-1.083 0-1.9-.056-2.507-.13-1.305-.16-2.16-1.175-2.282-2.4a40.194 40.194 0 01-.155-2.438.833.833 0 01.802-.864z"></path>
        <path d="M14.005 19.63a.834.834 0 01-1.178 1.179L9.91 17.892a.833.833 0 010-1.178l2.917-2.917a.833.833 0 011.178 1.178l-1.494 1.494H20.5a.833.833 0 110 1.667h-7.988l1.494 1.494z"></path>
      </g>
    </svg>
  )
}

export default LogoutIcon
